import React from 'react';
import { PathService } from '../../services';
import { LegalPostViewContext, TemplateProps } from '../../types';
import { LegalPostView } from '../../views';

const LegalPost: React.FunctionComponent<TemplateProps<
  LegalPostViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <LegalPostView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
    />
  );
};

export default LegalPost;
